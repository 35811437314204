<template>
    <div class="Reg">
        <div class="loginTop" v-if="mobileFlag">
                <img class="title_logo" src="../../assets//images/CwLogo.svg" alt="">
            
            <div class="title_icons">
                <div class="menu_icon" @click="changeLanguage(languageTxt)">
                    <img src="../../assets/images/LanguageIcon.svg" alt="">
                    {{ languageTxt == 'zh' ?'CN':'EN'  }}
                    
                </div>
            </div>
        </div>
        <div class="RegDiv">
            <div class="RegLeft">
                <div class="RegTitle">
                    <span class="RegTxt">
                        {{ $t('reg_title') }}
                    </span>
                    <span class="RegReturn">
                        {{ $t('loginRegReturn') }}
                        <b @click="goLogin()">
                            {{ $t('loginRegReturn1') }}
                        </b>
                    </span>
                </div>
                <el-form class="loginForm" ref="RegForm" label-position="top" :rules="rules" label-width="80px" :model="RegForm">
                    <el-form-item :label="$t('forget_email')" prop="email" >
                        <el-input type="text" v-model="RegForm.email" maxlength="250"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('forget_phone')" prop="phone" >
                        <el-input  v-model="RegForm.phone" maxlength="250"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('reg_username')" prop="userName" >
                        <el-input type="text" v-model="RegForm.userName" maxlength="250"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('login_password')" prop="password"  >
                        <el-input v-model="RegForm.password" autocomplete="off"  type="password" show-password maxlength="250"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('login_confirmpassword')" prop="confirm"  >
                        <el-input v-model="RegForm.confirm"  autocomplete="off"  type="password" show-password maxlength="250"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('forget_code')" prop="verifyCode"  >
                            <el-input v-model="RegForm.verifyCode" autocomplete="off" maxlength="6"  ></el-input>
                            <el-button class="sendCodeBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="sendCode" :disabled="dis">
                                
                                <span v-if="show">{{ $t('forget_sendcode') }}</span>
                                <span v-else>{{$t('forget_sendcodeAgain')+'('+count+'s)'}}</span>

                            </el-button>
                        </el-form-item>
                </el-form>
                <el-button class="RegBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="Reg">{{ $t('MyFollowers_Submit') }}</el-button>
                
            </div>
            
        </div>
        <div class="loginBottom">
            {{ $t('login_bottomTips') }}
        </div>
    </div>
  </template>
    
  <script>
  // @ is an alias to /src
  import { mapState } from "vuex";
  
  export default {
    name: "Reg",
    computed: {
      ...mapState(["mobileFlag", "lang"]),
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login_passwordNotNull')));
            } else {
                var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}');
                if(!pwdRegex.test(value)){
                    callback(new Error(this.$t('login_passwordNum')));
                }else if (this.RegForm.confirm !== '') {
                    this.$refs.RegForm.validateField('confirm');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login_password2NotNull')));
            } else if (value !== this.RegForm.password) {
                callback(new Error(this.$t('login_password2Notcorrect')));
            } else {
                callback();
            }
        };
      return {
        languageTxt:'',
        RegForm:{
            email: "",
            phone: "",
            userName: "",
            password: "",
            confirm: "",
            verifyCode: ""
        },
        fullscreenLoading:false,
        show:true,
        count:60,
        dis:false,
        isGrey: false, //按钮样式
        timer: null, //设置计时器,
        rules:{
            email:[
                { required: true, message: this.$t('login_emailNotNull'), trigger: 'blur' },
                { type: 'email', message: this.$t('login_emailError'), trigger: ['blur', 'change'] }
            ],
            password: [
                { validator: validatePass, trigger: 'blur' }
            ],
            confirm: [
                { validator: validatePass2, trigger: 'blur' }
            ],
            verifyCode:[
                { required: true, message: this.$t('forget_codeNotNull'), trigger: 'blur' },
            ],
        }
      };
    },
    created() {
        this.languageTxt =  this.lang
    },
    methods: {
        sendCode() {
            this.$refs.RegForm.validateField('email', valid => {
                if (!valid) {
                    this.fullscreenLoading = true
                    
                    this.$axios.get('/Api/Login/SendCode?email=' + this.RegForm.email).then((res) => {
                            let dt = res.data
                            if (dt.isSuccess) {
                                this.$notify.success({title: this.$t('nofity_success'), message:this.$t('send_success')});
                                this.code60(60)
                            } else {
                                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                            }
                    })
                    .finally(() => {
                        this.fullscreenLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
                
           
        },
        code60(time){
            let TIME_COUNT = time;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.dis = true;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) { 
                        this.count--;
                    } else {
                        this.dis = false;
                        this.isGrey = false;
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },
        Reg(){
            this.$refs.RegForm.validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true
                    let params = {
                        confirm: this.Rsa.encrypt(this.RegForm.confirm.trim()),
                        email: this.RegForm.email.trim(),
                        password: this.Rsa.encrypt(this.RegForm.password.trim()),
                        phone:this.RegForm.phone.trim(),
                        userName: this.RegForm.userName.trim(),
                        verifyCode:  this.RegForm.verifyCode.trim(),
                    }
                    this.$axios
                    .post("/Api/Login/Reigst", params)
                    .then((res) => {
                        let dt = res.data;
                        if (dt.isSuccess) {
                            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('reg_success')});
                            this.$router.push({ path: '/Login'});
                        } else {
                            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                        }
                    })
                    .finally(() => {
                        this.fullscreenLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        goLogin(){
            this.$router.push({ path: '/Login'});
        },
        changeLanguage(tag){
            
            if(tag == 'zh'){
                this.$i18n.locale = "en";
	            // localStorage.setItem('localeLanguage', lang)
                this.$store.commit("setLang",'en');
                this.languageTxt = 'en'
            }else{
                this.$i18n.locale = "zh";
                this.$store.commit("setLang",'zh');
                this.languageTxt = 'zh'

            }
        },
    },
  };
  </script>
  <style scoped lang="less">
 .Reg{
    background: #D5EAFD;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    .RegDiv{
        margin: 0  auto 20px auto;
        max-width: 520px;
        // max-height: 600px;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 80px;

        .RegLeft{
            width: 100%;
            background: #fff;
            padding: 20px 40px;
            .RegTitle{
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                .RegTxt{
                    color: #000;
                    font-family: 'PingFangSC-Semibold';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
                .RegReturn{
                    margin-top: 10px;
                    color: #a8a7a7;
                    b{
                        font-weight: 500;
                        color: #4D71FF;
                        cursor: pointer;
                    }
                }
                .RegLanguage{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    .languageIcon{
                        width: 20px;
                        height: 20px;
                    }
                    .languageTxt{
                        line-height: 20px;
                        color: #0B205D;
                        text-align: right;
                        font-family: 'PingFangSC-Medium';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        margin-left: 5px;
                    }
                }
            }
            .loginForm{
                margin-top: 20px;
                .sendCodeBtn{
                    border-radius: 4px;
                    background: #4D71FF;
                    padding: 8px 13px;
                    color: #FFF;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    position: absolute;
                    right: 16px;
                    top: 8px;
                }
            }
            .RegTips{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 20px;
                span{
                    color: #000;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
                b{
                    color: #4D71FF;
                    text-align: right;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .RegRight{
            
            width: 50%;
            img{
                height: 100%;
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }
        .RegBtn{
            border-radius: 4px;
            background: #0F1845;
            display: flex;
            width: 440px;
            padding: 10px 30px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-family: 'PingFangSC-Medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 40px;


        }
    }
    .loginBottom{
        color: #0F1845;
        font-family: 'PingFangSC-Medium';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 1200px;
        margin: 50px  auto 0 auto;
        padding-bottom: 30px;
        opacity: 0.3;
    }
 }
 @media screen and (max-width:767px) {
    .Reg{
        background: #fff;
        .RegDiv{
            position: relative;
            display: flex;
            flex-direction: column;
            top: 0;
            .RegLeft{
                width: calc(100% - 40px);
                padding: 30px 20px;
                .RegTitle{
                    .RegTxt{
                        font-size: 18px;
                    }
                    .RegForm{
                        margin-top: 30px;
                    }
                    
                }
                .RegTips{
                    span,b{
                        font-size: 14px;
                    }
                }
            }
            .RegBtn{
                width: 100%;
                background-color: #4D71FF;
                font-size: 14px;
            }
            
            .RegRight{
                display: none;
            }
        }
        .loginBottom{
            padding: 20px 20px 35px 20px;
            bottom: 0;
            background: #F4F4F4;
            color: #0F1845;
            font-size: 12px;
            position: relative;
            
        }
    }
 }
  </style>
    